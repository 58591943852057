<template>
  <!-- 登录主视图 -->
  <div id="LoginAdmin">
    <img class="logoImg" src="@/assets/img/logo1.png">
    <h1>浙江网标物联有限公司</h1>
    <div class="loginArea">
      <Form class="formLogin" :model="formData" :rules="ruleData">
        <FormItem prop="userName">
          <i-Input type="text" v-model="formData.userName" placeholder="用户名">
            <Icon type="ios-person-outline" slot="prepend"></Icon>
          </i-Input>
        </FormItem>
        <FormItem prop="password">
          <i-Input type="password" v-model="formData.password" placeholder="密码">
            <Icon type="ios-unlock-outline" slot="prepend"></Icon>
          </i-Input>
        </FormItem>
        <FormItem prop="code">
          <i-Input type="text" style="width: 130px; float:left" v-model="formData.code" placeholder="验证码">
            <Icon type="ios-key-outline" slot="prepend" />
          </i-Input>
          <div class="login-code" @click="refreshCode">
            <!--验证码组件-->
            <s-identify :identifyCode="identifyCode"></s-identify>
          </div>
        </FormItem>
        <FormItem>
          <Button type="primary" long @click="toSubmit">登录</Button>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
import {sha256} from 'js-sha256'
import '@/assets/css/global.css'
import SIdentify from './view/Sidentify'

export default {
  name: 'LoginAdmin',
  components: { SIdentify },
  data () {
    const isEqually = (rule, value, callback) => {
      if(this.formData.code != ''){
        if(this.formData.code == this.identifyCode){
          callback()
        }else{
          this.refreshCode();
          callback(new Error('验证码不正确'))
        }
      }else{
        callback()
      }
    }
    return {
      identifyCodes: '1234567890',
      identifyCode: '',
      formData: {
        userName: '',
        password: '',
        code: ''
      },
      ruleData: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { type: 'string', min: 5, message: '密码至少5位', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { validator: isEqually, trigger: 'blur'}
        ]
      }
    }
  },
  created () {
    this.refreshCode();
  },
  mounted () {
    this.$http({
      url: '/api/user/checklogin',
      method: 'GET'
    }).then(res => {
      if(res.data.code == 200){
        this.$router.push({path:'/admin'});
      }
    });
    this.identifyCode = '';
    this.makeCode(this.identifyCodes, 4);
  },
  methods: {
    /**
     * 登录
     */
    toSubmit() {
      // 获取 yyyy-mm-dd 格式的日期参数
      let date = new Date()
      let nowMonth = (date.getMonth()+1) >= 10 ? (date.getMonth()+1) : '0' + (date.getMonth()+1)
      let nowDay = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
      let nowDate = date.getFullYear() + '-' + nowMonth + '-' + nowDay

      //Ajax请求
      if(this.formData.code == this.identifyCode){
        this.$http({
          url: '/api/user/login',
          method: 'POST',
          data: {
            username: this.formData.userName,
            password: sha256(sha256(this.formData.password) + nowDate)
          }
        }).then(res => {
          if(res.data.code === 200){
            this.$Message.success(res.data.msg);
            //设置token
            this.$cookies.set('token', res.data.data)
            this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.data;
            setTimeout(() => {
              this.$router.push('/admin');
            },1000);
          }else{
            this.refreshCode();
            this.$Message.error(res.data.msg);
          }
        }).catch(() => {
          this.$Message.error('网络错误');
        })
      }
    },

    /**
     * 验证码相关
     */
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    }
  }
}
</script>

<style scoped>
#LoginAdmin {
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  background: url('../../assets/img/background.jpg') no-repeat center center fixed;
  background-size: cover;
}
.logoImg {
  width:200px;
  margin-top: 10%;
}
.loginArea {
  width: 400px;
  height: 250px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 75px;
  padding-top: 30px;
}
</style>
