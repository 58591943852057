<template>
  <!-- 首页视图 -->
  <div id="Banner">
    <Carousel autoplay :autoplay-speed="autoTime" v-model="BannerIndex" loop style="height: 60vh" v-on:on-click="clickBanner">
      <CarouselItem v-for="(item) in BannerList" :key="item.id">
        <img :src="item.img" alt="">
        <h2>{{item.name}}</h2>
        <!-- <p>{{item.description}}</p> -->
      </CarouselItem>
    </Carousel>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  data () {
    return {
      autoTime: 5000,
      BannerIndex: 0,
      BannerList: []
    }
  },
  beforeCreate () {
    this.$http({
      url: '/api/banner',
      method: 'GET'
    }).then(res => {
      if(res.data.code === 200){
        this.BannerList = res.data.data;
        this.BannerList.forEach(element => {
          element.img = this.$api.adminUrl + element.img;
        });
      }else{
        this.$Message.error(res.data.msg);
      }
    }).catch(() => {
      this.$Message.error('网络错误');
    })
  },
  methods:{
    clickBanner () {
      window.open(this.BannerList[this.BannerIndex].url);//新窗口打开
      // window.location.href = this.BannerList[this.BannerIndex].url;//当前窗口打开
    }
  }
}
</script>

<style scoped>
#Banner {
  width: 100%;
  height: 60vh;
  position: relative;
  margin-bottom: 10px;
}
#Banner img { 
  width: 100%;
  height: 60vh;
  object-fit: cover;
}
#Banner h2 { 
  position: absolute;
  top: 50%;
  margin-left: 70vw;
  color: white;
}
</style>