<template>
  <!-- 总文章视图 -->
  <div id="ArticleSummary">
    <Row style="padding-bottom: 30px">
      <i-Col span="12" v-for="(item,index) in menuData" :key="item.id">
        <Card style="margin:10px; cursor: pointer; color: #000" :bordered="false" :to="'/article/' + item.id">
          <img :src="imgUrl[index]">
          <div class="desc">
            <h2>{{item.title}}</h2>
            <span style="margin-right: 30px;">作者：{{item.author}}</span>
            <span>发布时间：{{item.time}}</span>
          </div>
        </Card>
      </i-Col>
    </Row>
    <div class="footerArticle">
      <a @click="() => {if(this.page > 1)this.page = this.page - 1; this.toQuery();}"><Icon type="ios-arrow-back" />上一页</a>
      <span>{{page}}</span>
      <a @click="() => {if(this.page < this.total_page )this.page = this.page + 1; this.toQuery();}">下一页<Icon type="ios-arrow-forward" /></a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ArticleSummary',
  data () {
    return{
      menu_id: this.$route.params.menu_id,
      menuData: [],
      imgUrl: [],
      page: 1,
      total_page: 1
    }
  },
  beforeMount () {
    this.toQuery();
  },
  methods:{
    /**
     * 得到子菜单文章
     */
    toQuery () {
      this.menu_id = this.$route.params.menu_id
      this.$http({
        url: '/api/article/menu/id',
        method: 'GET',
        params:{
          menu_id: this.menu_id,
          page: this.page,
          num: 6
        }
      }).then(res => {
        if(res.data.code === 200){
          this.menuData = res.data.data.data;
          this.total_page = res.data.data.total_page;
          // if(res.data.data.total == 1){
          //   this.$router.push({path:'/article/' + res.data.data.data[0].id});
          // }
          this.menuData.forEach(item => {
            this.imgUrl.push(this.$api.adminUrl + item.img);
          })
        }else{
          this.menuData = [];
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.data = [];
        this.$Message.error('网络错误');
      })
    },
  }
}
</script>

<style scoped>
#ArticleSummary {
  position: relative;
  width: 80%;
  background-color: rgb(248, 248, 248);
  min-height: 75vh;
  margin: 0 auto;
  margin-top: 150px;
}
#ArticleSummary img {
  background-repeat: no-repeat;
  background-position: center center;
  object-fit: contain;
  width: 80%;
  height: 200px;
}
#ArticleSummary .desc {
  background-color: rgb(248, 248, 248);
  text-align: left;
  padding: 10px;
  padding-left: 20px;
}
.footerArticle {
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 25px;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
}
.footerArticle a{
  position: absolute;
}
.footerArticle a:first-child{
  left: 20px;
}
.footerArticle a:last-child{
  right: 20px;
}
</style>