<template>
  <!-- 后台主视图 -->
  <div id="HomeAdmin">
    <wb-navigation ref="menu"></wb-navigation>
    <router-view @selectMenu="setMenuPath" />
  </div>
</template>

<script>
import Navigation from '@/components/admin/comp/Navigation'

export default {
  name: 'HomeAdmin',
  components: {
    wbNavigation: Navigation,
  },
  beforeCreate() {
    this.$http({
      url: '/api/user/checklogin',
      method: 'GET'
    }).then(res => {
      if(res.data.code != 200){
        this.$router.push({path:'/wbwl'});
      }
    });
  },
  methods: {
    // 修改菜单选中项
    setMenuPath () {
      this.$refs.menu.setActiveMenu()
    }
  }
}
</script>

<style scoped>
#HomeAdmin {
  width: 100%;
  height: auto;
}
</style>
