<template>
  <!-- 文章管理视图 -->
  <div id="ArticleAdmin">
    <div class="MainTitle">
      <h1>文章管理</h1>
    </div>
    <div class="MainTable">
      <Card shadow>
        <div class="table_top">
          <Button type="primary" @click="addArticle"><Icon type="md-add" />新增</Button>
          <Select v-model="menuMain" style="width:200px; margin-right:10px" v-on:on-change="changeMenuMain">
            <Option v-for="item in menuMainList" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
          <Select v-model="menuSub" style="width:200px" v-on:on-change="changeMenuSub">
            <Option v-for="item in menuSubList" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </div>
        <div class="table_bottom">
          <Table row-key="id" height='675' :row-class-name="rowClassName" :loading="loading" :columns="columns1" :data="data" border></Table>
          <Page :total="total" :current="curr_page" :page-size="num"  show-total show-elevator @on-change="changePage" style="margin-top: 10px"></Page>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleAdmin',
  data () {
    return {
      curr_page: null,
      total_page: null,
      total: null,
      num: null,
      menuMain: 'allMenu',
      menuMainList: [],
      menuSub: '',
      menuSubList: [],
      data: [],
      loading: false,
      columns1: [
        {
          title: 'ID',
          key: 'id',
          align: 'center'
        },
        {
          title: '图片',
          key: 'img',
          align: 'center',
          render: (h, params) => {
            return h('img', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  margin: '10px 10px 10px 0',
                  height: '100px',
                  width: '190px',
                  objectFit: 'cover'
                },
                attrs: {
                  src: this.$api.adminUrl + params.row.img
                }
            }, '图片')
          }
        },
        {
          title: '文章标题',
          key: 'title',
          align: 'center'
        },
        {
          title: '作者',
          key: 'author',
          align: 'center'
        },
        {
          title: '文章描述',
          key: 'desc',
          align: 'center'
        },
        {
          title: '创建时间',
          key: 'time',
          align: 'center'
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          render: (h,params) => {
          return h('div', [
            h('Button', {
              props: {
                type: 'success',
                size: 'small'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  let routeUrl = this.$router.resolve({path: '/article/' + params.row.id});//路由信息设置
                  window.open(routeUrl.href);//路由跳转
                }
              }
            }, '预览'),
            h('Button', {
              props: {
                type: 'primary',
                size: 'small'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.updataArticle(params.row.id);
                }
              }
            }, '修改'),
            h('Button', {
              props: {
                type: 'error',
                size: 'small'
              },
              on: {
                click: () => {
                  this.deleteArticle(params.row.id);
                }
              }
            }, '删除')
          ])
        }
        }
      ]
    }
  },
  beforeMount () {
    this.$emit("selectMenu");
    this.queryMenuMain();
    if(localStorage.getItem('currentPage') && localStorage.getItem('currentPage') != 'null'){
      if(localStorage.getItem('menuId') && localStorage.getItem('menuId') != 'null'){
        this.queryArticleById(localStorage.getItem('menuId'),localStorage.getItem('currentPage'),5);
      }else{
        this.queryArticle(Number(localStorage.getItem('currentPage')),5);
      }
    }else{
      if(localStorage.getItem('menuId') && localStorage.getItem('menuId') != 'null'){
        this.queryArticleById(localStorage.getItem('menuId'),1,5);
      }else{
        this.queryArticle(1,5);
      }
    }
  },
  destroyed () {
    localStorage.removeItem('menuId');
    localStorage.removeItem('currentPage');
  },
  methods:{
    /**
     *查询主菜单内容--显示有子菜单的主菜单
     */
    queryMenuMain (){
      this.$http({
        url: '/api/menu',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          res.data.data.forEach( (item) => {
            if(item.child.length != 0){
              this.menuMainList.push(item)
            }
          })
          if(localStorage.getItem('menuId')){
            this.menuSub = localStorage.getItem('menuId');
            this.menuMainList.forEach( (item) => {
              item.child.forEach( (itemChild) => {
                if(itemChild.id == this.menuSub){
                  this.menuSubList = item.child;
                  this.menuMain = item.id;
                  return false;
                }
              });
              if(this.menuMain != 'allMenu'){
                return false;
              }
            });
          }
          this.menuMainList.unshift({name: '全部', id: 'allMenu'});//添加全部选项
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     *主菜单选中内容改变
     */
    changeMenuMain (){
      this.menuSubList = [];
      this.menuSub = '';
      if(this.menuMain == 'allMenu'){
        this.queryArticle(1,5);
      }else{
        this.menuMainList.forEach(element => {
          if(element.id == this.menuMain){
            this.menuSubList = element.child;//子菜单数据导入
          }
        });
      }
    },

    /**
     *子菜单选中内容改变
     */
    changeMenuSub (){
      if(this.menuSub != '' && this.menuSub != undefined){
        localStorage.setItem('menuId',this.menuSub);
        this.queryArticleById(this.menuSub,1,5);
      }else{
        localStorage.removeItem('menuId');
      }
    },

    /**
     * 查询全部文章内容
     */
    queryArticle (page,num) {
      localStorage.setItem('currentPage',page);
      this.$http({
        url: '/api/article/all',
        method: 'GET',
        params:{
          page: page,
          num: num
        }
      }).then(res => {
        if(res.data.code === 200){
          this.data = res.data.data.data;
          this.curr_page = res.data.data.curr_page;
          this.total_page = res.data.data.total_page;
          this.total = res.data.data.total;
          this.num = res.data.data.num;
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     * 菜单ID查询文章内容
     */
    queryArticleById (menu_id,page,num) {
      localStorage.setItem('currentPage',page);
      this.$http({
        url: '/api/article/menu/id',
        method: 'GET',
        params:{
          menu_id: menu_id,
          page: page,
          num: num
        }
      }).then(res => {
        if(res.data.code === 200){
          this.data = res.data.data.data;
          this.curr_page = res.data.data.curr_page;
          this.total_page = res.data.data.total_page;
          this.total = res.data.data.total;
          this.num = res.data.data.num;
        }else{
          this.data = [];
          // this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.data = [];
        this.$Message.error('网络错误');
      })
    },

    /**
     * 新增文章
     */
    addArticle(){
      this.$router.push({path: 'editArticleAdmin', query: {currentPage: localStorage.getItem('currentPage'),menuId: localStorage.getItem('menuId')}});//路由跳转
    },

    /**
     * 修改文章
     */
    updataArticle (id) {
      this.$router.push({path: 'editArticleAdmin', query: {id: id,currentPage: localStorage.getItem('currentPage'),menuId: localStorage.getItem('menuId')}});//路由跳转
    },

    /**
     * 删除文章
     */
    deleteArticle(id){
      this.$http({
        url: '/api/article',
        method: 'DELETE',
        data: {
          id: Number(id)
        }
      }).then(res => {
        if(res.data.code === 200){
          if(this.menuMain == 'allMenu'){
            this.queryArticle(this.curr_page,this.num);
          }else{
            this.queryArticleById(this.menuSub,this.curr_page,this.num);
          }
          this.$Message.success(res.data.msg);
          this.tableRefresh();
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     * 换页
     */
    changePage(page){
      if(this.menuMain == 'allMenu'){
        this.queryArticle(page,this.num);
      }else{
        this.queryArticleById(this.menuSub,page,this.num);
      }
    },
    
    /**
     * 表格数据延时刷新
     */
    tableRefresh (){
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      },500);
    },

    /**
     *表格内容左对齐
     */
    rowClassName () {
      return 'demo-table-info-row';
    }
  }
}
</script>

<style scoped>
#ArticleAdmin {
  width: calc(100% - 250px);
  height: calc(82vh - 10px);
  background-color: rgb(248, 248, 248);
  position: absolute;
  right: 0;
  top: 110px;
}
.MainTitle {
  padding-top: 20px;
  padding-left: 20px;
  text-align: left;
}
.MainTable {
  padding: 20px
}
.table_top {
  text-align: left;
  margin-bottom: 10px;
}
.table_top button{
  margin-right: 10px;
}
</style>
