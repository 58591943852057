import { render, staticRenderFns } from "./ArticleSummary.vue?vue&type=template&id=61658232&scoped=true&"
import script from "./ArticleSummary.vue?vue&type=script&lang=js&"
export * from "./ArticleSummary.vue?vue&type=script&lang=js&"
import style0 from "./ArticleSummary.vue?vue&type=style&index=0&id=61658232&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61658232",
  null
  
)

export default component.exports