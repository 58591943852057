<template>
  <!-- 首页视图 -->
  <div id="Home">
    <wb-header :activeName="activeName"></wb-header>
    <router-view/>
    <wb-footer></wb-footer>
    <BackTop></BackTop>
  </div>
</template>

<script>
import Header from '@/components/index/comp/Header'
import Footer from '@/components/index/comp/Footer'

export default {
  name: 'Home',
  components: {
    wbHeader: Header,
    wbFooter: Footer
  },
  data () {
    return {
      activeName: 'index'
    }
  },
  beforeMount () {
    //获取文章对应的菜单id
    if(this.$route.params.id){
      this.$http({
        url: '/api/article',
        method: 'GET',
        params:{
          id: this.$route.params.id
        }
      }).then(res => {
        if(res.data.code === 200){
          this.activeName = res.data.data.menu_id;
        }else{
          this.activeName = 'index';
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    }else if(this.$route.params.menu_id){
      this.activeName = this.$route.params.menu_id;
    }else{
       this.activeName = 'index';
    }
    
  }
}
</script>

<style scoped>
#Home {
  width: 100%;
  height: auto;
}
</style>
