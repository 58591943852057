<template>
  <!-- 首页视图 -->
  <div id="Index">
    <wb-banner></wb-banner>
    <div class="IndexMain">
      <div class="card" v-for="(element,index) in articleData" :key="index">
        <div class="cardTitle">
          <h1>{{titleData[index].name}}</h1>
          <Divider style="margin-bottom: 50px;"><Icon size="30" type="md-paw" /></Divider>
        </div>
        <Row style="margin-bottom: 50px;">
          <i-Col span="8" v-for="(item,i) in element" :key="item.id">
            <Card class="cardMain" :bordered="false" :to="'/article/'+item.id">
              <img :src="imgUrl[index*3+i]">
              <div class="desc">
                <h2>{{item.title}}</h2>
                <span style="margin-right: 30px;">作者：{{item.author}}</span>
                <span>时间：{{item.time}}</span>
              </div>
              <Button class="cardButton">查看详细</Button>
            </Card>
          </i-Col>
        </Row>
        <Button class="seeMore" @click="articleMore(titleData[index].id)">查看更多<Icon size="20" type="ios-arrow-forward" /></Button>
      </div>
      
    </div>
  </div>
</template>

<script>
import Banner from '@/components/index/comp/Banner'

export default {
  name: 'Index',
  components: {
    wbBanner: Banner
  },
  data () {
    return{
      titleData:[],
      articleData:[],
      imgUrl: []
    }
  },
  beforeMount () {
    this.queryMenu();
  },
  methods:{
    /**
     * 首页内容查询
     */
    async queryMenu () {
      this.$http({
        url: '/api/menu',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.queryArticle(res)
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('1网络错误');
      })
    },

    /**
     * 子菜单对应文章查询
     */
    async queryArticle (res) {
      for(let element of res.data.data){
        if(element.child.length != 0){
          for(let item of element.child){
            let respoon = await this.$http({
              url: '/api/article/menu/id',
              method: 'GET',
              params:{
                menu_id: item.id,
                page: 1,
                num: 3
              }
            })
            if(respoon.data.code === 200 && respoon.data.data.total >= 3){
              let articleList = respoon.data.data.data
              if(articleList != 0){
                this.articleData.push(articleList);
                this.titleData.push(item);
                articleList.forEach(it => {
                  this.imgUrl.push(this.$api.adminUrl +it.img + '?t=' + (new Date().getTime()))
                })
              }
            }
            
          }
        }
      }
    },

    /**
     * 查看更多
     */
    articleMore (id) {
      this.$router.push({path:'/articleSummary/' + id});
    }
  }
}
</script>

<style scoped>
.IndexMain {
  width: 80%;
  margin: 0 auto;
}
.card .cardTitle {
  width: 20%;
  margin: 0 auto;
}
.card .cardMain {
  position: relative;
  height: 300px;
  margin:10px;
  cursor: pointer;
  color: #000;
  background-color: rgb(248, 248, 248);
  border-bottom: 5px ;
  border-bottom: 3px solid #86BC42;
}
.card .cardMain:hover {
  background-color: #FFF;
  box-shadow: 0 3px 6px 2px #666;
}
.card .cardMain:hover .cardButton {
  background: #2d3e50 none repeat scroll 0 0;
}
.card .cardMain img {
  position: absolute;
  top: -50px;
  left: 10%;
  background-repeat: no-repeat;
  background-position: center center;
  object-fit: cover;
  width: 400px;
  height: 200px;
}
.card .cardMain .desc {
  position: absolute;
  bottom: 50px;
  text-align: left;
  padding: 10px;
  padding-left: 20px;
}
.card .cardMain .cardButton {
  width: 30%;
  height: 50px;
  position: absolute;
  bottom: -25px;
  left: 35%;
  background-color: #86BC42;
  color: #fff;
}
.seeMore {
  width: 10%;
  height: 50px;
  background-color: #86BC42;
  color: #fff;
  font-size: 20px;
  margin-bottom: 100px;
}
</style>