import Vue from 'vue'
import App from './App.vue'
import API from '@/api/api.js'
import Axios from 'axios'
import router from './router'
import VueCookies from 'vue-cookies'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'


// 配置全局默认URI
Axios.defaults.baseURL = API.adminUrl

// 添加响应拦截器
Axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if(response.data.code == 205 && router.history.current.fullPath != '/wbwl') {
    router.push('/wbwl');
  }
  
  return response
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
});

Vue.config.productionTip = false
// 原型绑定全局API配置
Vue.prototype.$api = API
// 原型绑定Axios(Ajax)
Vue.prototype.$http = Axios

Vue.use(VueCookies)
Vue.use(ViewUI)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
