<template>
  <!-- 顶部导航栏 -->
  <div id="Header">
    <img class="logo" :src="logoUrl" alt="">
    <div class="header_right">
      <Menu v-if="menuList.length > 0" class="menu" mode="horizontal" :active-name="activeName" accordion>
         <!-- 首页 -->
        <MenuItem name="index" to="/index">
          <span>首页</span>
        </MenuItem>
        <div class="menumain" v-for="(menuItem) in menuList" :key="menuItem.id">
          <!-- 展开没有子菜单 -->
          <MenuItem v-if="!menuItem.child || menuItem.child.length==0" :name="menuItem.id" :to="menuItem.url.length > 0 ? menuItem.url : ''">
            {{menuItem.name}}
          </MenuItem>

          <!-- 展开有子菜单 -->
          <Submenu v-else :name="menuItem.id">
              <template slot="title">
                  <span>{{menuItem.name}}</span>
              </template>
              <MenuItem  v-for="(item) in menuItem.child" :key="item.id" :name="item.id" :to="item.url.length > 0 ? item.url : '/articleSummary/' + item.id">
                {{item.name}}
              </MenuItem>
          </Submenu>
        </div>
      </Menu>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    activeName: String
  },
  data () {
    return {
      logoUrl: '',
      menuList: []//菜单模块的数据
    }
  },
  beforeCreate () {
    //Logo图标获取
    this.$http({
      url: '/api/corporinfo',
      method: 'GET'
    }).then(res => {
      if(res.data.code === 200){
        this.logoUrl = this.$api.adminUrl + res.data.data.logo + '?t=' + (new Date().getTime());
      }else{
        this.$Message.error(res.data.msg);
      }
    }).catch(() => {
      this.$Message.error('网络错误');
    })

    //菜单目录获取
    this.$http({
      url: '/api/menu',
      method: 'GET'
    }).then(res => {
      if(res.data.code === 200){
        this.menuList = res.data.data;
      }else{
        this.$Message.error(res.data.msg);
      }
    }).catch(() => {
      this.$Message.error('网络错误');
    })
  }
}
</script>

<style scoped>
#Header {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  /* 顶部导航栏固定 */
  position:fixed;
  top:0px;
  right:0px;
  text-align:center;
  background-color: #fff;
  z-index:2;
  border-bottom: 1px solid #eee;
}
.logo {
  height: 60px;
  width: 400px;
  object-fit: contain;
  float: left;
  display: block;
}
.header_right {
  width: calc(100% - 480px);
  margin-right: 80px;
  float: right;
}
.menu {
  float: right;
  border-bottom: 0;
}
.menumain {
  float: right;
}
a {
  color: #515a6e;
}
</style>
