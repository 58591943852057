<template>
  <!-- 单一文章视图 -->
  <div id="Article" v-if="data">
    <div class="ArticleMain">
      <h1>{{data.title}}</h1>
      <div class="desc">
        <span>作者：{{data.author}}</span>
        <span>发布时间：{{data.time}}</span>
        <p style="margin-top:5px">描述：{{data.desc}}</p>
      </div>
      <div class="detail" v-html="details"></div>
      <div v-if="downarticle && uparticle" class="footerArticle">
        <a @click="clickArticle(uparticle.id)"><Icon type="ios-arrow-back" />{{uparticle.title}}</a>
        <a @click="clickArticle(downarticle.id)">{{downarticle.title}}<Icon type="ios-arrow-forward" /></a>
      </div>
    </div>
    <div class="ArticleNew">
      <Card>
        <h3 style="text-align: left" slot="title">
          <Icon type="ios-paper-outline" size="18" />
          最新相关文章
        </h3>
        <a href="#" slot="extra" @click.prevent="moreArticle">
          <Icon type="ios-loop-strong"></Icon>
          More
      </a>
        <ul>
          <li v-for="(item) in newData" :key="item.id">
            <a target="_blank" @click="clickArticle(item.id)">{{ item.title }}</a>
          </li>
        </ul>
    </Card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Article',
  data () {
    return {
      id: this.$route.params.id,
      data: null,
      details: "",
      newData: [],
      uparticle: null,
      downarticle: null
    }
  },
  beforeMount () {
    this.toQuery();
  },
  methods:{
    /**
     * 得到文章详情
     */
    toQuery () {
      this.id = this.$route.params.id
      this.$http({
        url: '/api/article',
        method: 'GET',
        params:{
          id: this.id
        }
      }).then(res => {
        if(res.data.code === 200){
          this.data = res.data.data;
          this.details = this.data.detail;
          this.getNewDetails()
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      });
    },

    /**
     * 得到最新文章
     */ 
    getNewDetails () {
      this.$http({
        url: '/api/article/menu/id',
        method: 'GET',
        params:{
          menu_id: this.data.menu_id,
          page: 1,
          num: 5
        }
      }).then(res => {
        if(res.data.code === 200){
          this.newData = res.data.data.data;
          this.getUpdown();
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      });
    },

    /**
     * 得到上下页信息
     */
    getUpdown () {
      this.$http({
        url: '/api/article/updown',
        method: 'GET',
        params:{
          id: this.data.id
        }
      }).then(res => {
        if(res.data.code === 200){
          this.uparticle = res.data.data.uparticle;
          this.downarticle = res.data.data.downarticle;
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      });
    },

    /**
     * 更多文章
     */
    moreArticle () {
      console.log('更多')
    },

    /**
     * 跳转到指定文章
     */
    clickArticle (id) {
      if (id != this.id && id != 0) {
        this.$router.push({path: '/article/' + id});//路由信息设置
      }
    }
  }
}
</script>

<style scoped>
#Article {
  width: 100%;
  background-color: rgb(248, 248, 248);
  margin-top: 120px;
  position: relative;
}
.ArticleMain {
  position: relative;
  margin: 0 auto;
  width: 60%;
  min-height: 75vh;
  background-color: #FFF;
}
.desc {
  background-color: rgb(248, 248, 248);
  width: 80%;
  height: 80px;
  margin: 10px auto;
  padding: 10px;
}
.desc span{
  float: left;
  margin-left: 30px;
}
.desc span:first-child{
  margin-left: 10px;
}
.desc p {
  clear: both;
  float: left;
  margin-left: 10px;
}
.detail {
  padding: 20px 24px 20px 24px;
}
.ArticleNew {
  width: 250px;
  background-color: blue;
  position: fixed;
  bottom: 280px;
  right: calc(80% + 5px);
  text-align: center;
  z-index: 2;
}
.ArticleNew li {
  list-style:none;
  text-align: left;
  overflow: hidden;      /*溢出隐藏*/
  white-space: nowrap;	/*规定文本不进行换行*/
  text-overflow: ellipsis;	/*当对象内文本溢出时显示省略标记（...）*/
}
.ArticleNew li a {
  font-size: 16px;
}
.footerArticle {
  width: 100%;
  position: absolute;
  bottom: 10px;
}
.footerArticle a{
 float: right;
 margin-right: 20px;
}
.footerArticle a:first-child{
 float: left;
 margin-left: 20px;
}
</style>