<template>
  <!-- Banner视图 -->
  <div id="BannerAdmin">
    <div class="MainTitle">
      <h1>Banner管理</h1>
    </div>
    <div class="MainTable">
      <Card shadow>
        <div class="table_top">
          <Button type="primary" @click="addBanner"><Icon type="md-add" />新增Banner</Button>
        </div>
        <div class="table_bottom">
          <Table row-key="id" height='700' :row-class-name="rowClassName" :loading="loading" :columns="columns1" :data="data1" border></Table>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerAdmin',
  data () {
    return {
      bannerName: '',
      bannerDescription: '',
      bannerImage: null,
      bannerUrl: '',
      addImg: require('@/assets/img/uploadImg.jpg'),
      loading: false,
      columns1: [
        {
          title: 'ID',
          key: 'id',
          align: 'center'
        },
        {
          title: '图片',
          key: 'img',
          align: 'center',
          render: (h, params) => {
            return h('img', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  margin: '10px 10px 10px 0',
                  height: '100px',
                  width: '190px',
                  objectFit: 'cover'
                },
                attrs: {
                  src: this.$api.adminUrl + params.row.img
                }
            }, '图片')
          }
        },
        {
          title: 'Banner名称',
          key: 'name',
          align: 'center'
        },
        {
          title: 'Banner说明',
          key: 'description',
          align: 'center'
        },
        {
          title: '链接网址',
          key: 'url',
          align: 'center'
        },
        {
          title: '创建时间',
          key: 'time',
          align: 'center'
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          render: (h, params) => {
          return h('div', [
            h('Button', {
              props: {
                type: 'primary',
                size: 'small'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.updataBanner(params.row.id,params.row.name,params.row.description,params.row.url);
                }
              }
            }, '修改'),
            h('Button', {
              props: {
                type: 'error',
                size: 'small'
              },
              on: {
                click: () => {
                  this.deleteBanner(params.row.id);
                }
              }
            }, '删除')
          ])
        }
        }
      ],
      data1: []
    }
  },
  beforeMount () {
    this.$emit("selectMenu");
    this.queryBanner();
  },
  methods:{
    /**
     * 查询Banner
     */
    queryBanner () {
      this.$http({
        url: '/api/banner',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.data1 = res.data.data;
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     * 增加Banner
     */
    addBanner () {
      this.$Modal.confirm({
        onOk: () => {
          let formData = new FormData()
          formData.append("name", this.bannerName)
          formData.append("description", this.bannerDescription)
          formData.append("image", this.bannerImage?this.bannerImage[0]:null)
          formData.append('url',this.bannerUrl)
          this.$http({
            url: '/api/banner',
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            data: formData
          }).then(res => {
            if(res.data.code === 200){
              this.queryBanner();
              this.$Message.success(res.data.msg);
              this.tableRefresh();
            }else{
              this.$Message.error(res.data.msg);
            }
          }).catch(() => {
            this.$Message.error('网络错误');
          })
        },
        render: (h) => {
          return h('div',{
            attrs: {
              style: 'text-align: right'
            },
          },[
            h('h2',{
              attrs: {
                style: 'text-align: center; margin-bottom: 10px'
              },
              domProps: {innerHTML: '新增Banner'}
            }),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '名称：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px'
              },
              props: {
                value: this.bannerName,
                autofocus: true,
                placeholder: '请输入Banner名称'
              },
              on: {
                input: (val) => {
                  this.bannerName = val;
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '说明：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px; margin-top: 10px; margin-bottom: 10px'
              },
              props: {
                value: this.bannerDescription,
                placeholder: '请输入Banner说明'
              },
              on: {
                input: (val) => {
                  this.bannerDescription = val;
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right; height:190px; line-height:190px'
              },
              domProps: {innerHTML: '图片：'}
            }),
            h('img',{
              attrs: {
                style: 'height: 100px; width: 190px; margin-right: 90px',
                src: this.addImg
              },
              on: {
                click: () => {
                  document.querySelector('#imgFile').click();
                }
              }
            }),
            h('input', {
              attrs: {
                id: 'imgFile',
                type: 'file',
                style: 'margin-top: 10px; display: none'
              },
              on: {
                change: (val) => {
                  //上传的内容
                  this.bannerImage = val.path[0].files;
                  //新增弹窗显示上传的图片
                  if (navigator.userAgent.indexOf("MSIE") >= 1) { // IE
                      this.addImg = document.getElementById('imgFile').value;
                  } else if (navigator.userAgent.indexOf("Firefox") > 0) { // Firefox
                      this.addImg = window.URL.createObjectURL(document.getElementById('imgFile').files.item(0));
                  } else if (navigator.userAgent.indexOf("Chrome") > 0) { // Chrome
                      this.addImg = window.URL.createObjectURL(document.getElementById('imgFile').files.item(0));
                  }
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '链接：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px; margin-top: 10px'
              },
              props: {
                value: this.bannerUrl,
                autofocus: true,
                placeholder: '请输入链接网址'
              },
              on: {
                input: (val) => {
                  this.bannerUrl = val;
                }
              }
            }),
          ])
        }
      })
    },

    /**
     * 修改Banner对话框
     */
    updataBanner (id,name,description,url) {
      var data = {
        id: Number(id),
        description: description,
        name: name,
        url: url
      };
      this.$Modal.confirm({
        onOk: () => {
          this.$http({
            url: '/api/banner',
            method: 'PUT',
            data: data
          }).then(res => {
            if(res.data.code === 200){
              this.queryBanner();
              this.$Message.success(res.data.msg);
              this.tableRefresh();
            }else{
              this.$Message.error(res.data.msg);
            }
          }).catch(() => {
            this.$Message.error('网络错误');
          })
        },
        render: (h) => {
          return h('div',{
            attrs: {
              style: 'text-align: right'
            },
          },[
            h('h2',{
              attrs: {
                style: 'text-align: center; margin-bottom: 10px'
              },
              domProps: {innerHTML: '修改Banner'}
            }),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '名称：'}
            }),
            h('Input', {
              props: {
                value: data.name,
                placeholder: '请输入Banner名称'
              },
              style: {
                width: '280px'
              },
              on: {
                input: (val) => {
                  data.name = val;
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '说明：'}
            }),
            h('Input', {
              attrs: {
                style: 'margin-top: 10px'
              },
              props: {
                value: data.description,
                placeholder: '请输入Banner说明'
              },
              style: {
                width: '280px'
              },
              on: {
                input: (val) => {
                  data.description = val;
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '链接：'}
            }),
            h('Input', {
              attrs: {
                style: 'margin-top: 10px'
              },
              props: {
                value: data.url,
                placeholder: '请输入链接网址'
              },
              style: {
                width: '280px'
              },
              on: {
                input: (val) => {
                  data.url = val;
                }
              }
            })
          ])
        }
      })
    },

    /**
     * 删除Banner
     */
    deleteBanner(id) {
      this.$http({
        url: '/api/banner',
        method: 'DELETE',
        data: {
          id: id
        }
      }).then(res => {
        if(res.data.code === 200){
          this.queryBanner();
          this.$Message.success(res.data.msg);
          this.tableRefresh();
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },
    
    /**
     * 表格数据延时刷新
     */
    tableRefresh (){
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      },500);
    },

    /**
     *表格内容左对齐
     */
    rowClassName () {
      return 'demo-table-info-row';
    }
  }
}
</script>

<style scoped>
#BannerAdmin {
  width: calc(100% - 250px);
  height: calc(82vh - 10px);
  background-color: rgb(248, 248, 248);
  position: absolute;
  right: 0px;
  top: 110px;
}
.MainTitle {
  padding-top: 20px;
  padding-left: 20px;
  text-align: left;
}
.MainTable {
  padding: 20px
}
.table_top {
  text-align: left;
  margin-bottom: 10px;
}
.table_top button{
  margin-right: 10px;
}
</style>
