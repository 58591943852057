<template>
  <!-- 修改文章视图 -->
  <div id="EditArticleAdmin" v-if="articleData">
    <div class="MainTitle" style="margin-top: 4vh">
      <h1>{{title}}</h1>
    </div>
    <Form v-model="articleData" :label-width="80" style="margin: 4vh auto; width: 1200px">
      <FormItem label="文章标题">
        <Input v-model="articleData.title" maxlength="30" placeholder="Enter something..." />
      </FormItem>
      <FormItem style="" label="文章作者">
        <Input v-model="articleData.author" maxlength="12" placeholder="Enter something..." />
      </FormItem>
      <FormItem label="绑定菜单" style="text-align: left">
        <Select v-model="menuMain" style="width:200px; margin-right: 10px" @on-change="changeMenuMain">
          <Option v-for="item in menuMainList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="articleData.menu_id" style="width:200px">
          <Option v-for="item in menuSubList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="文章描述">
        <Input v-model="articleData.desc" maxlength="120" placeholder="Enter something..." />
      </FormItem>
      <FormItem style="text-align: left;" label="文章主图">
        <img :src="imgUrl" style="height: 150px; width: 300px; object-fit: cover; cursor: pointer;" @click="$refs.uploadimg.click()">
        <input type="file" ref="uploadimg" name="image" style="width: 0; height: 0;" @change="updateChange($event)">
      </FormItem>
      <FormItem label="文章内容">
        <div id="editor">

        </div>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="toSubmit">提交</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import E from "wangeditor"

export default {
  name: 'EditArticleAdmin',
  data () {
    return {
      editor: null,
      title:'新增文章',
      menuMain: '',
      menuMainList: [],
      menuSubList: [],
      articleData: {
        title: '',
        desc: '',
        author: '',
        menu_id: '',
        img: '',
        detail: ''
      },
      imgUrl: require('@/assets/img/uploadImg.jpg')
    }
  },
  beforeMount () {
    //获取更新前信息--修改
    if(this.$route.query.id){
      this.$http({
        url: '/api/article',
        method: 'GET',
        params:{
          id: this.$route.query.id
        }
      }).then(res => {
        if(res.data.code === 200){
          this.articleData = res.data.data;
          this.imgUrl = this.$api.adminUrl + this.articleData.img + '?t=' + (new Date().getTime());//显示用主图内容的填充
          this.editor.txt.html(this.articleData.detail);//显示用富文本框内容的填充
          this.title = '修改文章';
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    }
    //查询主菜单内容
    this.queryMenuMain();
  },
  mounted () {
    //富文本框设置
    this.editor = new E("#editor");
    this.editor.config.height = 800;
    this.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
      // resultFiles 是 input 中选中的文件列表
      // insertImgFn 是获取图片 url 后，插入到编辑器的方法
      let formData = new FormData()
      formData.append("image", resultFiles[0])
      this.$http({
        url: '/api/article/cont/img',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }).then(res => {
        if(res.data.code === 200){
          // 上传图片，返回结果，将图片插入到编辑器中
          // insertImgFn(imgUrl)
          insertImgFn(this.$api.adminUrl + res.data.data);
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    };
    this.editor.create();
  },
  methods:{
    /**
     *查询主菜单内容--显示有子菜单的主菜单
     */
    queryMenuMain (){
      this.$http({
        url: '/api/menu',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          res.data.data.forEach( (item) => {
            if(item.child.length != 0){
              this.menuMainList.push(item)
            }
          })
          if(this.articleData.menu_id != ''){
            //修改
            this.menuMainList.forEach( (item) => {
              item.child.forEach( (itemChild) => {
                if(itemChild.id == this.articleData.menu_id){
                  this.menuSubList = item.child;
                  this.menuMain = item.id;
                  return false;
                }
              });
              if(this.menuMain != ''){
                return false;
              }
            });
          }else{
            //新增
            this.menuMain = this.menuMainList[0].id;
            this.menuSubList = this.menuMainList[0].child;
            this.articleData.menu_id = this.menuSubList[0].id;
          }
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     *主菜单选中内容改变--筛选子菜单目录
     */
    changeMenuMain (){
      this.menuSubList = [];
      this.articleData.menu_id = '';
      this.menuMainList.forEach(element => {
        if(element.id == this.menuMain){
          this.menuSubList = element.child;//子菜单数据导入
        }
      });
    },

    /**
     * 主图的上传
     */
    updateChange (file) {
      let formData = new FormData()
      formData.append("image", file.target.files[0])
      this.$http({
        url: '/api/article/main/img',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }).then(res => {
        if(res.data.code === 200){
          this.imgUrl = this.$api.adminUrl + res.data.data + '?t=' + (new Date().getTime());
          this.articleData.img = res.data.data;
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     *提交
     */
    toSubmit() {
      this.articleData.detail = this.editor.txt.html();//获取富文本框内容
      if(this.articleData.id){
        //修改
        this.$http({
          url: '/api/article',
          method: 'PUT',
          data: this.articleData
        }).then(res => {
          if(res.data.code === 200){
            this.$Message.success(res.data.msg);
            localStorage.setItem('currentPage',this.$route.query.currentPage);
            localStorage.setItem('menuId',this.$route.query.menuId);
            this.$router.push({path: '/admin/articleAdmin'});//返回文章管理页面
          }else{
            this.$Message.error(res.data.msg);
          }
        }).catch(() => {
          this.$Message.error('网络错误');
        })
      }else{
        //新增
        this.$http({
          url: '/api/article',
          method: 'POST',
          data: this.articleData
        }).then(res => {
          if(res.data.code === 200){
            this.$Message.success(res.data.msg);
            localStorage.setItem('currentPage',this.$route.query.currentPage);
            localStorage.setItem('menuId',this.$route.query.menuId);
            this.$router.push({path: '/admin/articleAdmin'});//返回文章管理页面
          }else{
            this.$Message.error(res.data.msg);
          }
        }).catch(() => {
          this.$Message.error('网络错误');
        })
      }
    }
  }
}
</script>

<style scoped>
#EditArticleAdmin {
  width: calc(100% - 250px);
  height: calc(90vh - 10px);
  background-color: rgb(248, 248, 248);
  position: absolute;
  right: 0px;
  top: 110px;
  overflow-y: auto;
}
</style>

<style src="../../../assets/css/editor.css" scoped></style>
