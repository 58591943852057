<template>
  <!-- 菜单视图 -->
  <div id="MenuAdmin">
    <div class="MainTitle">
      <h1>菜单管理</h1>
    </div>
    <div class="MainTable">
      <Card shadow>
        <div class="table_top">
          <Button type="primary" @click="addMainMenu"><Icon type="md-add" />新增主菜单</Button>
          <Button type="primary" @click="addSubmenu"><Icon type="md-add" />新增子菜单</Button>
        </div>
        <div class="table_bottom">
          <Table row-key="id" height='700' :row-class-name="rowClassName" :loading="loading" :columns="columns16" :data="data12" border></Table>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuAdmin',
  data () {
    return {
      mainMenuId: '',
      mainMenuName: '',
      childMenuId: '',
      childMenuName: '',
      loading: false,
      columns16: [
        {
          title: '菜单名称',
          key: 'name',
          align: 'center',
          tree: true
        },
        {
          title: 'ID',
          key: 'id',
          align: 'center'
        },
        {
          title: '排序',
          key: 'grade',
          align: 'center'
        },
        {
          title: '跳转链接',
          key: 'url',
          align: 'center'
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          render: (h, params) => {
          return h('div', [
            h('Button', {
              props: {
                type: 'primary',
                size: 'small'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.updataMenuModel(params.row.id,params.row.name,params.row.grade,params.row.url);
                }
              }
            }, '修改'),
            h('Button', {
              props: {
                type: 'error',
                size: 'small'
              },
              on: {
                click: () => {
                  this.deleteMenu(params.row.id);
                }
              }
            }, '删除')
          ])
        }
        }
      ],
      data12: []
    }
  },
  beforeMount () {
    this.$emit("selectMenu");
    this.queryMenu();
  },
  methods:{
    /**
     * 查询菜单
     */
    queryMenu () {
      this.$http({
        url: '/api/menu',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.data12 = res.data.data;
          for (let i=0; i<this.data12.length; i++) {
            this.data12[i].children = this.data12[i].child;
          }
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     * 增加主菜单
     */
    addMainMenu () {
      this.$Modal.confirm({
        onOk: () => {
          this.$http({
            url: '/api/main/menu',
            method: 'POST',
            data: {
              name: this.mainMenuName
            }
          }).then(res => {
            if(res.data.code === 200){
              this.queryMenu();
              this.$Message.success(res.data.msg);
              this.tableRefresh();
            }else{
              this.$Message.error(res.data.msg);
            }
          }).catch(() => {
            this.$Message.error('网络错误');
          })
        },
        render: (h) => {
          return h('div',{
            attrs: {
              style: 'text-align: right'
            },
          },[
            h('h2',{
              attrs: {
                style: 'text-align: center; margin-bottom: 10px'
              },
              domProps: {innerHTML: '新增主菜单'}
            }),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '名称：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px'
              },
              props: {
                value: this.mainMenuName,
                autofocus: true,
                placeholder: '请输入主菜单名称'
              },
              on: {
                input: (val) => {
                  this.mainMenuName = val;
                }
              }
            })
          ])
        }
      })
    },

    /**
     * 增加子菜单
     */
    addSubmenu () {
      this.$Modal.confirm({
        onOk: () => {
          this.$http({
            url: '/api/child/menu',
            method: 'POST',
            data: {
              name: this.childMenuName,
              father_id: this.mainMenuId
            }
          }).then(res => {
            if(res.data.code === 200){
              this.queryMenu();
              this.$Message.success(res.data.msg);
              this.tableRefresh();
            }else{
              this.$Message.error(res.data.msg);
            }
          }).catch(() => {
            this.$Message.error('网络错误');
          })
        },
        render: (h) => {
          let arr = []
          for (let i=0; i<this.data12.length;i++) {
            arr.push(h('Option',{
              props: {
                value: this.data12[i].id,
                label: this.data12[i].name
              }
            }))
          }
          return h('div',{
            attrs: {
              style: 'text-align: right'
            },
          },[
            h('h2',{
              attrs: {
                style: 'text-align: center; margin-bottom: 10px'
              },
              domProps: {innerHTML: '新增子菜单'}
            }),
            h('label',{
              attrs: {
                style: 'text-align: right;'
              },
              domProps: {innerHTML: '名称：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px'
              },
              props: {
                value: this.childMenuName,
                placeholder: '请输入子菜单名称'
              },
              on: {
                input: (val) => {
                  this.childMenuName = val;
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '主菜单：'}
            }),
            h('Select', {
              attrs: {
                style: 'text-align: left; margin-top: 10px; width: 280px'
              },
              props: {
                value: this.mainMenuId,
                placeholder: '请选择主菜单'
              },
              on: {
                input: (val) => {
                  console.log(val)
                  this.mainMenuId = val;
                }
              }
            }, arr)
          ])
        }
      })
    },

    /**
     * 修改菜单对话框
     */
    updataMenuModel (id,name,grade,urlc) {
      var url = '/api/main/menu';
      var data = {
        id: id,
        name: name,
        grade: grade,
        url: urlc
      };
      var father_id = '';
      var isFa = true;
      if(id[0] == 'C'){
        url = '/api/child/menu';
        this.data12.forEach(element => {
          if(isFa){
            father_id = element.id;
            element.children.forEach(item => {
              if(item.id == id){
                isFa = false;
              }
            })
          }
        });
        data['father_id'] = father_id;
      }
      this.$Modal.confirm({
        onOk: () => {
          this.updateMenu(data,url);
        },
        render: (h) => {
          if(data.id[0] == 'C'){
            let arr = []
            for (let i=0; i<this.data12.length;i++) {
              arr.push(h('Option',{
                props: {
                  value: this.data12[i].id,
                  label: this.data12[i].name
                }
              }))
            }
            return h('div',{
              attrs: {
                style: 'text-align: right'
              },
            },[
              h('h2',{
                attrs: {
                  style: 'text-align: center; margin-bottom: 10px'
                },
                domProps: {innerHTML: '修改子菜单'}
              }),
              h('label',{
                attrs: {
                  style: 'text-align: right;'
                },
                domProps: {innerHTML: 'ID：'}
              }),
              h('Input', {
                attrs: {
                  style: 'width: 280px'
                },
                props: {
                  value: data.id,
                  placeholder: '请输入子菜单ID'
                },
                on: {
                  input: (val) => {
                    data.id = val;
                  }
                }
              }),
              h('br'),
              h('label',{
                attrs: {
                  style: 'text-align: right'
                },
                domProps: {innerHTML: '名称：'}
              }),
              h('Input', {
                attrs: {
                  style: 'width: 280px; margin-top: 10px'
                },
                props: {
                  value: data.name,
                  placeholder: '请输入子菜单名称'
                },
                on: {
                  input: (val) => {
                    data.name = val;
                  }
                }
              }),
              h('br'),
              h('label',{
                attrs: {
                  style: 'text-align: right'
                },
                domProps: {innerHTML: '排序：'}
              }),
              h('Input', {
                attrs: {
                  style: 'width: 280px; margin-top: 10px'
                },
                props: {
                  value: data.grade,
                  placeholder: '请输入子菜单排序'
                },
                on: {
                  input: (val) => {
                    data.grade = Number(val);
                  }
                }
              }),
              h('br'),
              h('label',{
                attrs: {
                  style: 'text-align: right'
                },
                domProps: {innerHTML: '链接：'}
              }),
              h('Input', {
                attrs: {
                  style: 'width: 280px; margin-top: 10px'
                },
                props: {
                  value: data.url,
                  placeholder: '请输入子菜单跳转链接'
                },
                on: {
                  input: (val) => {
                    data.url = val;
                  }
                }
              }),
              h('br'),
              h('label',{
                attrs: {
                  style: 'text-align: right'
                },
                domProps: {innerHTML: '主菜单：'}
              }),
              h('Select', {
                attrs: {
                  style: 'width: 280px; text-align: left; margin-top: 10px'
                },
                props: {
                  value: data.father_id,
                  placeholder: '请选择主菜单名称'
                },
                on: {
                  input: (val) => {
                    data.father_id = val;
                  }
                }
              }, arr)
            ])
          }
          return h('div',{
            attrs: {
              style: 'text-align: right'
            },
          },[
            h('h2',{
              attrs: {
                style: 'text-align: center; margin-bottom: 10px'
              },
              domProps: {innerHTML: '修改主菜单'}
            }),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: 'ID：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px'
              },
              props: {
                value: data.id,
                placeholder: '请输入主菜单ID'
              },
              on: {
                input: (val) => {
                  data.id = val;
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '名称：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px; margin-top: 10px'
              },
              props: {
                value: data.name,
                placeholder: '请输入主菜单名称'
              },
              on: {
                input: (val) => {
                  data.name = val;
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '排序：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px; margin-top: 10px'
              },
              props: {
                value: data.grade,
                placeholder: '请输入主菜单排序'
              },
              on: {
                input: (val) => {
                  data.grade = Number(val);
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '链接：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px; margin-top: 10px'
              },
              props: {
                value: data.url,
                placeholder: '请输入主菜单跳转链接'
              },
              on: {
                change: (val) => {
                  data.url = val;
                }
              }
            })
          ])
        }
      })
    },

    /**
     * 修改主菜单
     */
    updateMenu (data,url){
      console.log(data)
      this.$http({
        url: url,
        method: 'PUT',
        data: data
      }).then(res => {
        if(res.data.code === 200){
          this.queryMenu();
          this.$Message.success(res.data.msg);
          this.tableRefresh();
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     * 删除菜单
     */
    deleteMenu (id) {
      var url = '/api/main/menu';
      if(id[0] == 'C'){
        url = '/api/child/menu';
      }
      this.$http({
        url: url,
        method: 'DELETE',
        data: {
          id: id
        }
      }).then(res => {
        if(res.data.code === 200){
          this.queryMenu();
          this.$Message.success(res.data.msg);
          this.tableRefresh();
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     * 表格数据延时刷新
     */
    tableRefresh (){
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      },500);
    },

    /**
     *表格内容左对齐
     */
    rowClassName () {
      return 'demo-table-info-row';
    }
  }
}
</script>

<style scoped>
#MenuAdmin {
  width: calc(100% - 250px);
  height: calc(82vh - 10px);
  background-color: rgb(248, 248, 248);
  position: absolute;
  right: 0px;
  top: 110px;
}
.MainTitle {
  padding-top: 20px;
  padding-left: 20px;
  text-align: left;
}
.MainTable {
  padding: 20px
}
.table_top {
  text-align: left;
  margin-bottom: 10px;
}
.table_top button{
  margin-right: 10px;
}
</style>
<style>
.ivu-table .demo-table-info-row td{
  text-align: left;
}
</style>