<template>
  <!-- 友情链接视图 -->
  <div id="LinksAdmin">
    <div class="MainTitle">
      <h1>友情链接管理</h1>
    </div>
    <div class="MainTable">
      <Card shadow>
        <div class="table_top">
          <Button type="primary" @click="addLink"><Icon type="md-add" />新增链接</Button>
        </div>
        <div class="table_bottom">
          <Table row-key="id" height='700' :row-class-name="rowClassName" :loading="loading" :columns="columns1" :data="data1" border></Table>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinksAdmin',
  data () {
    return {
      linkName: '',
      linkUrl: '',
      loading: false,
      columns1: [
        {
          title: 'ID',
          key: 'id',
          align: 'center'
        },
        {
          title: '名称',
          key: 'name',
          align: 'center'
        },
        {
          title: '跳转链接',
          key: 'url',
          align: 'center'
        },
        {
          title: '创建时间',
          key: 'time',
          align: 'center'
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          render: (h, params) => {
          return h('div', [
            h('Button', {
              props: {
                type: 'primary',
                size: 'small'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.updataLink(params.row.id,params.row.name,params.row.url);
                }
              }
            }, '修改'),
            h('Button', {
              props: {
                type: 'error',
                size: 'small'
              },
              on: {
                click: () => {
                  this.deleteLink(params.row.id);
                }
              }
            }, '删除')
          ])
        }
        }
      ],
      data1: []
    }
  },
  beforeMount () {
    this.$emit("selectMenu");
    this.queryLink();
  },
  methods:{
    /**
     * 查询菜单
     */
    queryLink () {
      this.$http({
        url: '/api/links',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.data1 = res.data.data;
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     * 增加链接
     */
    addLink () {
      this.$Modal.confirm({
        onOk: () => {
          this.$http({
            url: '/api/links',
            method: 'POST',
            data: {
              name: this.linkName,
              url: this.linkUrl
            }
          }).then(res => {
            if(res.data.code === 200){
              this.queryLink();
              this.$Message.success(res.data.msg);
              this.tableRefresh();
            }else{
              this.$Message.error(res.data.msg);
            }
          }).catch(() => {
            this.$Message.error('网络错误');
          })
        },
        render: (h) => {
          return h('div',{
            attrs: {
              style: 'text-align: right'
            },
          },[
            h('h2',{
              attrs: {
                style: 'text-align: center; margin-bottom: 10px'
              },
              domProps: {innerHTML: '新增链接'}
            }),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '名称：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px'
              },
              props: {
                value: this.linkName,
                autofocus: true,
                placeholder: '请输入链接名称'
              },
              on: {
                input: (val) => {
                  this.linkName = val;
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '链接：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px; margin-top: 10px'
              },
              props: {
                value: this.linkUrl,
                autofocus: true,
                placeholder: '请输入链接地址'
              },
              on: {
                input: (val) => {
                  this.linkUrl = val;
                }
              }
            }),
          ])
        }
      })
    },

    /**
     * 修改链接对话框
     */
    updataLink (id,name,url) {
      var data = {
        id: id,
        name: name,
        url: url
      };
      this.$Modal.confirm({
        onOk: () => {
          this.$http({
            url: '/api/links',
            method: 'PUT',
            data: data
          }).then(res => {
            if(res.data.code === 200){
              this.queryLink();
              this.$Message.success(res.data.msg);
              this.tableRefresh();
            }else{
              this.$Message.error(res.data.msg);
            }
          }).catch(() => {
            this.$Message.error('网络错误');
          })
        },
        render: (h) => {
          return h('div',{
            attrs: {
              style: 'text-align: right'
            },
          },[
            h('h2',{
              attrs: {
                style: 'text-align: center; margin-bottom: 10px'
              },
              domProps: {innerHTML: '修改链接'}
            }),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: 'ID：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px'
              },
              props: {
                value: data.id,
                placeholder: '请输入链接ID'
              },
              on: {
                input: (val) => {
                  data.id = val;
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '名称：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px; margin-top: 10px'
              },
              props: {
                value: data.name,
                placeholder: '请输入链接名称'
              },
              on: {
                input: (val) => {
                  data.name = val;
                }
              }
            }),
            h('br'),
            h('label',{
              attrs: {
                style: 'text-align: right'
              },
              domProps: {innerHTML: '链接：'}
            }),
            h('Input', {
              attrs: {
                style: 'width: 280px; margin-top: 10px'
              },
              props: {
                value: data.url,
                placeholder: '请输入链接跳转地址'
              },
              on: {
                input: (val) => {
                  data.url = val;
                }
              }
            })
          ])
        }
      })
    },

    /**
     * 删除链接
     */
    deleteLink(id) {
      this.$http({
        url: '/api/links',
        method: 'DELETE',
        data: {
          id: id
        }
      }).then(res => {
        if(res.data.code === 200){
          this.queryLink();
          this.$Message.success(res.data.msg);
          this.tableRefresh();
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     * 表格数据延时刷新
     */
    tableRefresh (){
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      },500);
    },

    /**
     *表格内容左对齐
     */
    rowClassName () {
      return 'demo-table-info-row';
    }
  }
}
</script>

<style scoped>
#LinksAdmin {
  width: calc(100% - 250px);
  height: calc(82vh - 10px);
  background-color: rgb(248, 248, 248);
  position: absolute;
  right: 0px;
  top: 110px;
}
.MainTitle {
  padding-top: 20px;
  padding-left: 20px;
  text-align: left;
}
.MainTable {
  padding: 20px
}
.table_top {
  text-align: left;
  margin-bottom: 10px;
}
.table_top button{
  margin-right: 10px;
}
</style>
