import Vue from 'vue'
import VueRouter from 'vue-router'

//前台路由
import Home from '@/components/index/Home'
import Index from '@/components/index/view/Index'
import Article from '@/components/index/view/Article'
import ArticleSummary from '@/components/index/view/ArticleSummary'

// 后台路由
import LoginAdmin from '@/components/admin/LoginAdmin'
import HomeAdmin from '@/components/admin/HomeAdmin'
import MenuAdmin from '@/components/admin/view/MenuAdmin'
import LinksAdmin from '@/components/admin/view/LinksAdmin'
import BannerAdmin from '@/components/admin/view/BannerAdmin'
import CorporinfoAdmin from '@/components/admin/view/CorporinfoAdmin'
import ArticleAdmin from '@/components/admin/view/ArticleAdmin'
import EditArticleAdmin from '@/components/admin/view/EditArticleAdmin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/index',
    component: Home,
    children: [
      {
        path: 'index',
        name: 'Index',
        component: Index
      },
      {
        path: 'article/:id',
        name: 'Article',
        component: Article
      },
      {
        path: 'articleSummary/:menu_id',
        name: 'ArticleSummary',
        component: ArticleSummary
      }
    ]
  },{
    path: '/wbwl',
    name: 'LoginAdmin',
    component: LoginAdmin
  },{
    path: '/admin',
    name: 'HomeAdmin',
    redirect: '/admin/menuAdmin',
    component: HomeAdmin,
    children: [
      {
        path: 'menuAdmin',
        name: 'MenuAdmin',
        component: MenuAdmin
      },
      {
        path: 'linksAdmin',
        name: 'LinksAdmin',
        component: LinksAdmin
      },
      {
        path: 'bannerAdmin',
        name: 'BannerAdmin',
        component: BannerAdmin
      },
      {
        path: 'corporinfoAdmin',
        name: 'CorporinfoAdmin',
        component: CorporinfoAdmin
      },
      {
        path: 'articleAdmin',
        name: 'ArticleAdmin',
        component: ArticleAdmin
      },
      {
        path: 'editArticleAdmin',
        name: 'EditArticleAdmin',
        component: EditArticleAdmin
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
