<template>
  <div id="Navigation" :style="'height:'+fullHeight+'px;'">
    <!-- logo图标 -->
    <img class="logo" :src="logoUrl">

    <!-- 顶部导航菜单 -->
    <div class="header">
      <Button type="error" class="update" @click="isUpdate = true"><Icon size="19" type="ios-exit-outline" />修改登录密码</Button>
      <Button type="primary" class="back" @click="() => {this.$router.push('/');}"><Icon size="18" type="md-return-left" />前台首页</Button>
      <Button type="error" class="exit" @click="toSignOut"><Icon size="19" type="ios-exit-outline" />退出登录</Button>
    </div>

    <div class="footer">
      <span>Copyright © WBWL 2021.All right reserved.Powered by Mattxy</span>
      <span>Version 1.0.0</span>
    </div>

    <!-- 侧栏导航 -->
    <div class="lefter">
      <div class="operator">
        <Divider orientation="left">操作人</Divider>
        <Avatar style="float: left; margin-left:50px; margin-right: 25px;  margin-bottom: 16px" icon="ios-person" size="large" />
        <h2 style="float: left">admin</h2>
        <Divider orientation="left">主导航</Divider>
      </div>
      <Menu class="lefter_menu" accordion v-on:on-select="selectMenu" :active-name="isSelect" theme="dark">
        <!-- <MenuItem name="/admin/indexAdmin" to="indexAdmin"><Icon :type="isSelect == '/admin/indexAdmin' ? 'ios-contact' : 'ios-contact-outline'" />账户管理</MenuItem> -->
        <MenuItem name="/admin/menuAdmin" to="menuAdmin"><Icon :type="isSelect == '/admin/menuAdmin' ? 'ios-menu' : 'ios-menu-outline'" />菜单管理</MenuItem>
        <MenuItem name="/admin/bannerAdmin" to="bannerAdmin"><Icon :type="isSelect == '/admin/bannerAdmin' ? 'ios-photos' : 'ios-photos-outline'" />Banner管理</MenuItem>
        <MenuItem name="/admin/corporinfoAdmin" to="corporinfoAdmin"><Icon :type="isSelect == '/admin/corporinfoAdmin' ? 'ios-list-box' : 'ios-list-box-outline'" />企业信息</MenuItem>
        <MenuItem name="/admin/linksAdmin" to="linksAdmin"><Icon :type="isSelect == '/admin/linksAdmin' ? 'ios-link' : 'ios-link-outline'" />友情链接</MenuItem>
        <MenuItem name="/admin/articleAdmin" to="articleAdmin"><Icon :type="isSelect == '/admin/articleAdmin' ? 'ios-paper' : 'ios-paper-outline'" />文章管理</MenuItem>
      </Menu>
    </div>

    <!-- 修改密码对话框 -->
     <Modal v-model="isUpdate" :closable="false"  width="360">
        <div style="text-align:center">
          <h2 style="margin-bottom: 10px">修改密码</h2>
          <Form ref="formPassword" :model="formPassword" :rules="rulePassword">
            <FormItem prop="password1">
                <Input type="password" v-model="formPassword.password1" placeholder="请输入密码" />
            </FormItem>
            <FormItem prop="password2">
                <Input type="password" v-model="formPassword.password2" placeholder="请再次输入密码" />
            </FormItem>
        </Form>
        </div>
        <div slot="footer">
          <Button type="text" size="large" @click="() => {this.isUpdate = false;}">取消</Button>
          <Button type="primary" size="large" :loading="loadingPassword" @click="toUpdatePassword">确定</Button>
        </div>
    </Modal>
  </div>
</template>

<script>
import {sha256} from 'js-sha256'

window.onload = function(){
  
};

export default {
  name: 'Navigation',
  data () {
    const isEqually = (rule, value, callback) => {
      if(this.formPassword.password1 != '' && this.formPassword.password2 != ''){
        if(this.formPassword.password1 == this.formPassword.password2){
          callback()
        }else{
          callback(new Error('两次密码输入不相同'))
        }
      }else{
        callback()
      }
    }
    return {
      fullHeight: document.documentElement.clientHeight,
      isSelect: this.$route.path,
      logoUrl: '',
      //修改密码使用的变量
      isUpdate: false,
      loadingPassword: false,
      formPassword: {
        password1: '',
        password2: ''
      },
      rulePassword: {
        password1: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { type: 'string', min: 5, message: '密码至少5位', trigger: 'blur' },
        ],
        password2: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { type: 'string', min: 5, message: '密码至少5位', trigger: 'blur' },
          { validator: isEqually, trigger: 'blur'}
        ]
      }
    }
  },
  beforeMount () {
    //Logo图标获取
    this.$http({
      url: '/api/corporinfo',
      method: 'GET'
    }).then(res => {
      if(res.data.code === 200){
        this.logoUrl = this.$api.adminUrl + res.data.data.logo + '?t=' + (new Date().getTime());
      }else{
        this.$Message.error(res.data.msg);
      }
    }).catch(() => {
      this.$Message.error('网络错误');
    });
    if(this.$route.path =='/admin/editArticleAdmin'){
      this.isSelect = '/admin/articleAdmin';
    }
    this.isSelect = this.$route.path;
    // document.getElementById('Navigation').style.height = document.body.offsetHeight
  },
  methods: {
    selectMenu (name) {
      this.isSelect = name;
    },

    /**
     * 修改选中项
     */ 
    setActiveMenu () {
      this.isSelect = this.$route.path
    },

    /**
     * 修改密码
     */
    toUpdatePassword () {
      this.modal_loading = true;
      setTimeout(() => {
          this.modal_loading = false;
          this.isUpdate = true;
          if(this.formPassword.password1.length >= 5 && this.formPassword.password2.length >= 5 && this.formPassword.password1 == this.formPassword.password2){
            this.$http({
              url: '/api/user/password',
              method: 'PUT',
              data: {
                password: sha256(this.formPassword.password2)
              }
            }).then(res => {
              if(res.data.code === 200){
                this.$Message.success(res.data.msg);
                this.isUpdate = false;
              }else{
                this.$Message.error(res.data.msg);
              }
            }).catch(() => {
              this.$Message.error('网络错误');
            })
          }else{
            this.$Message.error('输入内容不规范');
          }
      }, 200);
    },

    /**
     * 退出登录
     */
    toSignOut () {
      this.$http({
        url: '/api/user/signout',
        method: 'DELETE',
      }).then(res => {
        if(res.data.code === 200){
          this.$Message.success(res.data.msg);
          this.$cookies.remove('token')
          this.$http.defaults.headers.common['Authorization'] = '';
          this.$router.push('/wbwl')
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    }
  }
}
</script>

<style scoped>
#Navigation {
  height: 90vh;
  display: flex;
  position: relative;
  background-color: #515a6e;
}
.logo {
  width: 230px;
  height: 60px;
  margin: 10px;
  display: block;
  float: left;
}
.header {
  position: relative;
  width: calc(100% - 230px);
  background-color: #FFF;
}
.header button{
  position: absolute;
  height: 4vh;
  font-size: 16px;
  top: 2vh
}
.update {
  right: 15vw;
}
.back{
  right: 8.5vw;
}
.exit{
  right: 2vw;
}
.operator {
  width: 250px;
}
.lefter {
  width: 250px;
  position: absolute;
  top: 80px;
}
.lefter .ivu-menu {
  font-size: 18px;
  font-weight: bold;
}
.lefter .ivu-menu-item {
  font-size: 18px;
  font-weight: bold;
}
.lefter_menu{
  text-align: left;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 250px;
  height: 50px;
  width: calc(100% - 250px);
}
.footer span:last-child {
  position: absolute;
  right: 20px;
}
</style>
