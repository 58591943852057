<template>
  <!-- 企业信息视图 -->
  <div id="CorporinfoAdmin" v-if="formItem">
    <div class="MainTitle" style="margin-top: 4vh">
      <h1>企业信息</h1>
    </div>
    <Form v-model="formItem" :label-width="80" style="margin: 4vh auto; width: 500px">
      <FormItem label="公司名称">
        <Input :readonly="!isShow" v-model="formItem.name" placeholder="Enter something..." />
      </FormItem>
      <FormItem label="公司电话">
        <Input :readonly="!isShow" v-model="formItem.phone" placeholder="Enter something..." />
      </FormItem>
      <FormItem label="公司邮箱">
        <Input :readonly="!isShow" v-model="formItem.mail" placeholder="Enter something..." />
      </FormItem>
      <FormItem label="公司地址">
        <Input :readonly="!isShow" v-model="formItem.address" placeholder="Enter something..." />
      </FormItem>
      <FormItem style="height: 8vh; margin-top: 4vh" label="Logo">
        <img :src="logo" height="50px" style="float: left; margin-right: 50px; margin-left: 50px">
        <Upload action="/api/logo" style="float: left" :before-upload="toUpload">
          <Button style="height: 50px" icon="ios-cloud-upload-outline">上传Logo</Button>
        </Upload>
      </FormItem>
      <FormItem label="备案号">
        <Input :readonly="!isShow" v-model="formItem.record" placeholder="Enter something..." />
      </FormItem>
      <FormItem label="信息">
        <Input :readonly="!isShow" v-model="formItem.copyright" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder="Enter something..." />
      </FormItem>
      <FormItem>
        <Button type="primary" v-if="!isShow" @click="isChange">修改</Button>
        <Button type="primary" v-if="isShow" @click="toSubmit">提交</Button>
        <Button v-if="isShow" @click="toCancel" style="margin-left: 8px">取消</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  name: 'CorporinfoAdmin',
  data () {
    return {
      formItem: null,
      logo: '',
      isShow:false
    }
  },
  beforeMount () {
    this.$emit("selectMenu");
    this.queryCorporinfo();
  },
  methods:{
    /**
     * 查询Corporinfo
     */
    queryCorporinfo () {
      this.$http({
        url: '/api/corporinfo',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.formItem = res.data.data;
          this.logo = this.$api.adminUrl + res.data.data.logo + '?t=' + (new Date().getTime());
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     * 上传Logo
     */
    toUpload(file){
      let formData = new FormData()
      formData.append("image", file)
      this.$http({
        url: '/api/logo',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }).then(res => {
        if(res.data.code === 200){
          this.logo = this.$api.adminUrl + res.data.data + '?t=' + (new Date().getTime());
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
      return false;
    },

    /**
     * 提交修改信息
     */
    toSubmit(){
      this.$http({
        url: '/api/corporinfo',
        method: 'PUT',
        data: this.formItem
      }).then(res => {
        if(res.data.code === 200){
          this.isShow = false;
          this.queryCorporinfo();
          this.$Message.success(res.data.msg);
        }else{
          this.$Message.error(res.data.msg);
        }
      }).catch(() => {
        this.$Message.error('网络错误');
      })
    },

    /**
     * 取消修改操作
     */
    toCancel(){
      this.isShow = false;
      this.queryCorporinfo();
    },
    /**
     * 修改开关
     */
    isChange(){
      this.isShow = true;
    }
  }
}
</script>

<style scoped>
#CorporinfoAdmin {
  width: calc(100% - 250px);
  height: calc(82vh - 10px);
  background-color: rgb(248, 248, 248);
  position: absolute;
  right: 0px;
  top: 110px;
}
</style>