<template>
  <!-- 底部导航栏 -->
  <div id="Footer">
    <Row class="footerBj">
      <i-Col span="4" offset="3">
        <Card :bordered="false">
          <p slot="title">联系我们</p>
          <p><Icon type="ios-call" size="20" />{{corporinfoList.phone}}</p>
          <p><Icon type="ios-mail" size="20" />{{corporinfoList.mail}}</p>
          <p><Icon type="md-globe" size="20" />www.zjid.net</p>
          <p><Icon type="ios-pin" size="20" />{{corporinfoList.address}}</p>
        </Card>
      </i-Col>
      <i-Col span="4" offset="3">
        <Card :bordered="false">
          <p slot="title">友情链接</p>
          <p v-for="item in linksList" :key="item.id"><a :href="item.url">{{item.name}}</a></p>
        </Card>
      </i-Col>
      <i-Col span="4" offset="3">
        <Card :bordered="false">
          <p slot="title">加入我们</p>
          <p><Icon type="ios-call" size="20" />18562632357</p>
          <p><Icon type="ios-mail" size="20" />{{corporinfoList.mail}}</p>
        </Card>
      </i-Col>
    </Row>
    <div class="recordInfo">
      <p><a href="/">{{corporinfoList.copyright}}</a></p>
      <p><a target="_blank" href="https://beian.miit.gov.cn/">{{corporinfoList.record}}</a></p>
      <p><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33080202000484">
        <img :src="jhPhoto" style="float:left;"/>{{jhString}}
      </a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return{
      linksList:[],//友情链接数据
      corporinfoList:{},//联系我们数据
      jhString: "浙公网安备 33080202000484号",
      jhPhoto: require("@/assets/img/jh.png")
    }
  },
  beforeCreate () {
    //联系我们
    this.$http({
      url: '/api/corporinfo',
      method: 'GET'
    }).then(res => {
      if(res.data.code === 200){
        this.corporinfoList = res.data.data;
      }else{
        this.$Message.error(res.data.msg);
      }
    }).catch(() => {
      this.$Message.error('网络错误');
    })

    //友情链接
    this.$http({
      url: '/api/links',
      method: 'GET'
    }).then(res => {
      if(res.data.code === 200){
        this.linksList = res.data.data;
      }else{
        this.$Message.error(res.data.msg);
      }
    }).catch(() => {
      this.$Message.error('网络错误');
    })
  }
}
</script>

<style scoped>
#Footer {
  width: 100%;
  height: 280px;
  background-color: #2d3e50;
}
.footerBj {
  height: 220px;
  padding: 20px;
  color: #2d3e50;
}
.ivu-card {
  background-color: #2d3e50;
  color: white;
}
.ivu-card-head p {
  color: white;
  font-size: 2.5vh;
  line-height: 2.5vh !important;
  height: 2.5vh;
}
.ivu-card /deep/ .ivu-card-body {
  text-align: left
}
.ivu-card-body p {
  color: white;
  font-size: 1.5vh;
  line-height: 2vh !important;
  height: 2vh;
}
.ivu-card-body p a {
  color: white;
}
.ivu-card-body a:hover {
  color: #2d8cf0;
}
.ivu-icon {
  margin-left: 5px;
  margin-right: 10px;
}
.recordInfo {
  position: relative;
  width:100%;
  margin:0 auto;
  padding:20px 0;
  background-color: #86BC42;
  color: #FFF;
}
.recordInfo p {
  display:inline-block;
  text-decoration:none;
  height:20px;
  line-height:20px;
}
.recordInfo p a {
  float:left;
  height:20px;
  line-height:20px;
  margin: 0px 0px 0px 5px;
  color: #FFF;
}
.recordInfo p:first-child{
  position: absolute;
  left: 10%;
}
.recordInfo p:last-child{
  position: absolute;
  right: 10%;
}
</style>
